body.show-workoutsession .field-text .form-control {
    max-height: 100px;
    overflow-y: auto;
}

#main .table-responsive .actions a {
    white-space: nowrap;
}

.content {
    min-height: 250px;
}

/*body .content-wrapper {*/
/*    background: #F5F5F5;*/
/*}*/

input.btn, input.btn:hover, input.btn:active, input.btn:focus, input.btn:active:hover {
    background: #CCC;
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: none;
    color: #222222;
    display: inline-block;
    line-height: 1.42857143;
    opacity: 1;
    outline: none;
    padding: 6px 12px;
    text-align: center;
}
.pull-right {
    float: right !important;
}

.easyadmin .sidebar-menu .header {
    margin-top: 10px;
    color: #000;
    font-weight: bold;
}

.alert.alert-error {
    background-color: #f5948c;
}
